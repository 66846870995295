<template>
    <v-card :color="ant" min-height="350" class="pb-10">
          <v-card-title class="card-header">
            <span>Order Files </span>
            <v-spacer></v-spacer>
            <v-btn :color="primaryBlue" large @click="show = true"  v-if="getSession.user_type === 2">Upload File</v-btn>
            <!-- <v-btn :color="essayGreen" class="float-right "  v-if="$route.name === 'orderDetail' && files.length !== 0 && getSession.user_type === 1 && !getOrderDetail.accept " @click="acceptAnswer" :loading="loading"> Accept Answer</v-btn>   -->
          </v-card-title>

          <v-card-text v-if="files.length !== 0">
            <fileList :files="files" />
          </v-card-text>

          <v-card-text v-if="files.length === 0 && getSession.user_type === 2" class="" >
            <v-row class="mt-10">
              <v-col cols="3"></v-col>
              <v-col cols="6">
                <div class="text-center">
                  <v-icon large color="blue">
                    mdi-arrange-bring-forward
                  </v-icon>

                  <h3 class="mt-5">Drag & Drop your files here</h3>

                  <v-row no-gutters class="mx-auto">
                    <v-col class="ml-10 mr-5">
                      <v-divider class="mt-10  mb-5"></v-divider>
                    </v-col>
                    <v-col cols="1" class="mt-7 ">
                      OR
                    </v-col>
                    <v-col class="mr-10 ml-5">
                      <v-divider class="mt-10  mb-7"></v-divider>
                    </v-col>
                  </v-row>

                  <v-btn
                    large
                    width="200px"
                    rounded
                    color="blue"
                    @click="show=true"
                  >
                    Browse</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
          </v-card-text>
          <PlaceHolder
            v-if="files.length === 0 && getSession.user_type === 1"
            :src="require('@/assets/img/svg/no-notifications.svg')"
            text="Writer will upload drafts and completed order files soon..."
          />

         <AnswerUploadModal :show="show" @close="handleAnswerUpload" />
      </v-card>
</template>

<script>
import { mapGetters, mapMutations} from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: "FilesUpload",
  mixins: [colorMxn, validationMxn],
  components: {
    PlaceHolder: () => import("@/components/core/PlaceHolder"),
    AnswerUploadModal: () => import("@/components/answerUploadModal"),
    fileList: () => import("@/components/fileList")
  },
  data() {
    return {
      show: false,
      upload: true,
      loading: false,
      files: [],
    };
  },
  computed: {
    ...mapGetters(['getUploadedFile', 'getUploadedAnswer', 'getSession', 'getOrderDetails']),
  },
  watch: {
    getUploadedAnswer(val) {
    this.files = val;
    }
  },
  mounted() {
    this.files = this.getUploadedAnswer;
    this.getFiles();
  },
  methods: {
    ...mapMutations(['setMountUploadedAnswer', 'setReload']),
    async getFiles() {
      this.loading = true;
      const payload = {
        order_id: this.$route.params.id,
        upload_type: ['revision', 'draft', 'final', 'answer'],
      };
      const fullPayload = {
        params: payload,
        endpoint: '/order-detail/files',
      }
      const response = await this.performGetActions(fullPayload);
      this.setMountUploadedAnswer(response);
      this.loading = false;
    },
    async acceptAnswer() {
      this.loading = true;
      const payload = {
        accept: 1,
        
      };
      const fullPayload = {
        params: payload,
        endpoint: `/orders/${this.$route.params.id}`,
      }
      await this.performPutActions(fullPayload);
      this.setReload(true);
      this.loading = false;
    },
    handleAnswerUpload(val) {
      if (val) {
        this.getFiles();
      }
      this.show = false;
    },
  }
};
</script>

<style scoped>
.fileupload {
  height: 450px;
  overflow-y: scroll;
}

.fileupload::-webkit-scrollbar {
  display: none;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
</style>